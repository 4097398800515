import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import Alert from '@/components/atoms/Alert';
import { ButtonLink } from '@/components/atoms/Button';
import RoutePlanner from '@/components/routePlanner/RoutePlanner';
import URLS from '@/constants/url';
import { ArrowBack } from '@/icons';

interface ConnectionNoReturnRouteProps {
  routeMessage: string;
}

const ConnectionNoReturnRoute: React.FC<ConnectionNoReturnRouteProps> = ({ routeMessage }) => (
  <div>
    <Alert>{routeMessage}</Alert>
    <div className="flex sm:flex-col lg:gap-4">
      <div className="w-full lg:w-2/3 flex flex-col">
        <div className="flex sm:flex-col sm:items-center lg:justify-between lg:mt-14">
          <div className="lg:hidden mt-3">
            <ButtonLink href={URLS.HOMEPAGE} variant="secondary">
              <ArrowBack className="w-3 h-3 mr-0.5 fill-current" />
              <div className="sm:w-48 inline-block overflow-ellipsis text-center whitespace-nowrap overflow-hidden">
                <Trans i18nKey="no.return.route.back.to.homepage" />
              </div>
            </ButtonLink>
          </div>
          <div className="sm:hidden">
            <Link href={URLS.HOMEPAGE}>
              <a className="flex items-center sm:mt-2 text-primary-blue font-bold">
                <ArrowBack className="w-3 h-3 mr-0.5 fill-current" />
                <Trans i18nKey="no.return.route.back.to.homepage" />
              </a>
            </Link>
          </div>

          <ButtonLink href={URLS.RESERVATION.PASSENGERS} className="sm:my-2" variant="secondary">
            <Trans i18nKey="no.return.route.buy.ticket.there" />
          </ButtonLink>
        </div>
      </div>
      <div className="w-full lg:w-1/3 mt-4 lg:mt-14">
        <RoutePlanner />
      </div>
    </div>
  </div>
);

export default ConnectionNoReturnRoute;
