import ParagraphImageRow from '../strapi/ParagraphImageRow';
import { addDays, isSameDay, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  useBookingActions,
  useConnection,
  useHeaders,
  useLocations,
  useSetResponseState,
  useUser,
  utils,
} from '@web/shop-logic';
import { ConnectionRoute } from '@web/shop-logic/dist/hooks/useConnection';
import Alert from '@/components/atoms/Alert';
import Clamp from '@/components/atoms/Clamp';
import FormatTime from '@/components/atoms/FormatTime';
import FormSwitch from '@/components/atoms/Switch';
import BenefitBanner from '@/components/benefitBanner/BenefitBanner';
import LoginBanner from '@/components/benefitBanner/LoginBanner';
import ConnectionAlternative from '@/components/connection/ConnectionAlternative';
import ConnectionCard from '@/components/connection/ConnectionCard';
import ConnectionNoReturnRoute from '@/components/connection/ConnectionNoReturnRoute';
import MoreConnectionsButtons from '@/components/connection/MoreConnectionsButtons';
import ConnectCardSkeleton from '@/components/loader/ConnectCardSkeleton';
import Tariff from '@/components/registration/tariffOptions';
import RoutePlanner from '@/components/routePlanner/RoutePlanner';
import TimeTicketCard from '@/components/search/TimeTicketCard';
import useCurrentBooking from '@/hooks/useCurrentBooking';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ComponentShopActionPrices, EarlyBookingInfo, ShopFaresImageEntity } from '@/models/types';
import { groupBy } from '@/utils/arrayUtils';
import { formatConnectionTime, formatTime } from '@/utils/dateTimeUtils';
import {
  createParamArray,
  getFirstParamValue,
  isQueryValid,
  mapConnectionQueryToPayload,
} from '@/utils/routerUtils';

interface ConnectionResultsProps {
  actionPricesColors?: ComponentShopActionPrices[];
  sroTicketsFetching: boolean;
  setSroTicketsFetching: (arg: boolean) => void;
  setRoutesLoading: (arg: boolean) => void;
  faresImagesData: ShopFaresImageEntity[];
  earlyBookingInfos: EarlyBookingInfo[];
}

const ConnectionResults: React.FC<ConnectionResultsProps> = ({
  actionPricesColors,
  sroTicketsFetching,
  setSroTicketsFetching,
  setRoutesLoading,
  faresImagesData,
  earlyBookingInfos,
}) => {
  const { push, query } = useRouter();
  const {
    data,
    loading,
    fetchForwardRoutes,
    fetchBackwardRoutes,
    fetchConnection,
    shouldFindSro,
    fetchSroRoutes,
  } = useConnection();
  const { createBooking } = useBookingActions();
  const { currency } = useHeaders();
  const { isMobile } = useMobileWidth();
  const { isLoggedIn, isCreditPrice } = useUser();
  const { direction, booking } = useCurrentBooking();
  const { t } = useTranslation();
  const { getDestination } = useLocations();
  const setState = useSetResponseState(2);

  const [sroTicketsRoutes, setSroTicketsRoutes] = useState<ConnectionRoute[]>();

  const isAnyBookingComplete = [booking?.there?.bookingState, booking?.back?.bookingState].includes(
    'ADDONS_SELECTED',
  );
  const isDepartureToday = isSameDay(new Date(data?.routes?.[0]?.departureTime), new Date());
  const connectionsByDay = Array.from(
    groupBy((sroTicketsFetching ? sroTicketsRoutes : data?.routes) || [], (r) =>
      formatConnectionTime(r.departureTime, 'LONG_WEEKDAY'),
    ).entries(),
  );

  const connectionPayload = mapConnectionQueryToPayload(query);

  const changeSroRoute = async (dayDiff: number) => {
    const date = new Date(connectionPayload.departureDate);
    const departureDate = !!dayDiff ? addDays(date, dayDiff) : subDays(date, dayDiff);
    push({
      query: {
        ...query,
        departureDate: formatTime(departureDate, 'YEAR-MONTH-DAY'),
      },
      hash: 'sro' /* Rozpoznává, mají-li se stahovat místenky viz sroTicketsFetching initial state */,
    });
  };

  useEffect(() => {
    if (data?.routes?.length && isDepartureToday) {
      /**
       * Aktualizace seznamu spoju ve chvili kdy prvni odjede.
       * Jen u dnesnich - u budoucich muze pretect integer a fce se spusti hned.
       */
      setTimeout(() => {
        fetchConnection(connectionPayload);
      }, new Date(data?.routes[0].departureTime).valueOf() - new Date().valueOf());
    }
  }, [data?.routes]);

  useEffect(() => {
    /**
     * Cesta tam: vytváříme nový booking
     * Cesta zpět: Jen přidáme
     */
    if (isQueryValid(query, 'search')) {
      if (!direction || direction === 'there') createBooking(connectionPayload);
      // currency is not set on first render
      setTimeout(() => fetchConnection(connectionPayload), 0);
    }
  }, [query, currency]);

  useEffect(() => {
    /**
     *  Needs to be set it a bit later otherwise it is reset again in useConnectionRoute
     */
    setTimeout(() => {
      if (query?.isRouteInvalid) {
        setState(t('connection.after.planned.departure'), false);
      }
    }, 250);
  }, []);

  useEffect(() => {
    if (sroTicketsFetching) {
      fetchSroRoutes({
        fromLocationType: connectionPayload.fromLocationType,
        fromLocation: connectionPayload.fromLocationId,
        toLocationType: connectionPayload.toLocationType,
        toLocation: connectionPayload.toLocationId,
        departureDate: connectionPayload.departureDate,
        numberOfPassengers: connectionPayload.tariffs.length,
      }).then(setSroTicketsRoutes);
    }
  }, [sroTicketsFetching, query]);

  useEffect(() => {
    setRoutesLoading(loading);
  }, [loading]);

  /**
   * Nenalezeny žádné výsledky
   */
  if (!loading && !data?.routes.length && data?.routesMessage && !isAnyBookingComplete) {
    const from = getDestination(connectionPayload.fromLocationId);
    const to = getDestination(connectionPayload.toLocationId);
    const earlyBookingInfo = earlyBookingInfos.find(
      ({ fromCountry, toCountry, fromStation, toStation, cityPair }) =>
        ((from.placeType === 'STATION' && from.id.toString() === fromStation?.station.stationId) ||
          (from.placeType === 'CITY' ? from.id : from.cityId).toString() ===
            cityPair?.connection?.from.cityId ||
          (from.placeType === 'STATION' ? from.countryCode : from.stations[0]?.countryCode) ===
            fromCountry) &&
        ((to.placeType === 'STATION' && to.id.toString() === toStation?.station.stationId) ||
          (to.placeType === 'CITY' ? to.id : to.cityId).toString() ===
            cityPair?.connection?.to.cityId ||
          (to.placeType === 'STATION' ? to.countryCode : to.stations[0]?.countryCode) ===
            toCountry),
    )?.content;
    return (
      <>
        <Alert>{data.routesMessage}</Alert>
        <ConnectionAlternative payload={connectionPayload} />
        {!!earlyBookingInfo && (
          <div className="mt-4">
            <ParagraphImageRow component={earlyBookingInfo} />
          </div>
        )}
      </>
    );
  }

  /**
   * if return route does not exist but route there already exists
   *  Offer user to buy just ticket direction there
   */
  if (!loading && !data?.routes.length && isAnyBookingComplete) {
    return <ConnectionNoReturnRoute routeMessage={data?.routesMessage} />;
  }

  /* Výsledky vyhledávání */
  return (
    <>
      {/**
       * Upozornění na nošení roušek, pravidla pro překročení hranic apod.
       */}
      {(data?.textBubbles || []).map(({ id, text }) => (
        <Alert key={id} className="mb-2 break-words wysiwyg">
          <Clamp rows={isMobile ? 4 : 2}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Clamp>
        </Alert>
      ))}
      <div className="flex lg:w-2/3 items-center justify-between gap-2">
        <h1 className="h1 flex-grow-1">
          <Trans
            i18nKey={
              getFirstParamValue(query.direction) === 'back'
                ? 'basket.chooseRoute.return'
                : 'basket.chooseRoute.outward'
            }
          />
        </h1>
        {shouldFindSro && (
          <div className="flex gap-2 items-center mr-2">
            <Trans i18nKey="index.sroTickets" />
            <FormSwitch
              checked={sroTicketsFetching}
              onChange={(_, checked) => setSroTicketsFetching(checked)}
            />
          </div>
        )}
      </div>
      <div className="lg:w-2/3 lg:pr-2.5">
        {/* Provedeme check na spoje bez přestupu*/}
        {!loading && data?.routes?.every((r) => r.transfersCount) && direction === 'there' && (
          <div className="mb-3">
            <ConnectionAlternative payload={connectionPayload} withoutTransfer />
          </div>
        )}
        {/* Nenalezen žádný spoj na požadované datum. Zobrazuji nejbližší spoje */}
        {!loading && (sroTicketsFetching ? sroTicketsRoutes?.length === 0 : data?.routesMessage) && (
          <Alert className="my-1">
            <Trans i18nKey="results.selectedDateNotAvailable" />
          </Alert>
        )}
      </div>
      <div className="flex sm:flex-col lg:gap-4">
        <div className="w-full lg:w-2/3 flex flex-col">
          {/* Loading během načítání výsledků */}
          {loading ? (
            <>
              <p className="mt-0.5 lg:mt-1 mb-2 lg:mb-3 font-normal sm:text-14">
                <FormatTime
                  date={getFirstParamValue(
                    utcToZonedTime(new Date(query.departureDate as string), 'Europe/Prague'),
                  )}
                  formatType="LONG_WEEKDAY"
                />
              </p>
              <ConnectCardSkeleton />
              <ConnectCardSkeleton />
              <ConnectCardSkeleton />
            </>
          ) : (
            <>
              {connectionsByDay.map(([day, items]) => {
                /* Dlouhodobe jizdenky funguji jen pro vlaky bez prestupu */

                const correctSroConnection = items.find(
                  (connection) =>
                    connection.transfersCount === 0 &&
                    connection.vehicleTypes.includes('TRAIN') &&
                    connection.freeSeatsCount,
                );

                const isRegional = utils.isRegional(
                  correctSroConnection?.departureStationId,
                  correctSroConnection?.arrivalStationId,
                );

                /* Nabídnout časovky jen jednomu cestujícímu v rámci R8, R23 */
                const hasTimeTicket = connectionPayload.tariffs.length === 1 && isRegional;

                const offerFlexiOnSoldOut =
                  connectionPayload.tariffs.length === 1 &&
                  isRegional &&
                  items.some((i) => i.freeSeatsCount);

                return (
                  <React.Fragment key={day}>
                    <p className="mt-0.5 lg:mt-1 mb-2 lg:mb-3 sm:text-base font-bold">{day}</p>
                    <div className="w-full flex flex-col">
                      {hasTimeTicket && (
                        <TimeTicketCard
                          routePayload={{
                            fromStationId: correctSroConnection.departureStationId,
                            routeId: correctSroConnection.id,
                            tariffs: createParamArray(query.tariffs) as Tariff[],
                            toStationId: correctSroConnection.arrivalStationId,
                          }}
                        />
                      )}
                      <ul aria-label={t('accessibility.connection.results')}>
                        {(sroTicketsFetching ? sroTicketsRoutes || items : items).map((route) => (
                          <li key={route.id + route.departureStationId + route.arrivalStationId}>
                            <ConnectionCard
                              faresImagesData={faresImagesData}
                              actionPricesColors={actionPricesColors}
                              routeOverview={route}
                              isRjSro={sroTicketsFetching && !!sroTicketsRoutes}
                              offerFlexiOnSoldOut={
                                offerFlexiOnSoldOut &&
                                !route.transfersCount &&
                                route.vehicleTypes.every((v) => v === 'TRAIN')
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </React.Fragment>
                );
              })}
              <MoreConnectionsButtons
                isToday={isDepartureToday}
                onPreviousClick={async () => {
                  window.scrollTo(0, 0);
                  await (sroTicketsFetching ? changeSroRoute(-1) : fetchBackwardRoutes());
                }}
                onNextClick={async () => {
                  window.scrollTo(0, 0);
                  await (sroTicketsFetching ? changeSroRoute(1) : fetchForwardRoutes());
                }}
              />
            </>
          )}
        </div>
        <div className="w-full lg:w-1/3 lg:mt-14">
          {isAnyBookingComplete ? (
            <RoutePlanner />
          ) : (
            <>
              {!isLoggedIn && (
                <LoginBanner className="flex flex-col gap-2 items-start lg:mb-2 sm:mt-2" />
              )}
              {!isCreditPrice && <BenefitBanner className="sm:hidden" />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConnectionResults;
