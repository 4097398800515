import React from 'react';
import StrapiImage from '@/components/content/StrapiImage';
import { UploadFile } from '@/models/types';

interface ArticleBoxProps {
  header: string;
  image: Pick<UploadFile, 'url' | 'width' | 'height' | 'formats' | 'name'>;
  orderIndex: number;
  type: string;
  url: string;
}
/**
 * Box pro odkaz obsahující obrázek a pod ním text
 */
const ArticleBox: React.FC<ArticleBoxProps> = ({ image, orderIndex, header, url, type }) => (
  <li>
    <a
      href={url}
      id={`gtm-${type}-${orderIndex}`}
      aria-label={header}
      className="group hover:text-primary-blue transition-color duration-300"
    >
      <div className="relative overflow-hidden h-articleBannerMobile lg:h-articleBanner mb-1 rounded-sm bg-primary-bluelight2 group-hover:shadow-modal transition-shadow duration-300">
        <StrapiImage
          alt={header}
          image={image}
          className="group-hover:transform group-hover:scale-110 transition-transform duration-200 ease-in"
        />
      </div>

      <h3 className="h3">{header}</h3>
    </a>
  </li>
);

export default ArticleBox;
