import { Trans } from 'next-i18next';
import React from 'react';
import Banner from '@/components/atoms/Banner';
import { ChevronRight } from '@/icons';
import { ShopHpNewsCarouselEntity } from '@/models/types';
import { formatTime, isVisible } from '@/utils/dateTimeUtils';

interface NewsBoxProps {
  header: string;
  orderIndex: number;
  publishDate: string;
  url: string;
}
/**
 * Box pro odkaz obsahující datum a text
 */
const NewsBox: React.FC<NewsBoxProps> = ({ publishDate, header, url, orderIndex }) => (
  <li>
    <Banner
      id={`gtm-news-${orderIndex}`}
      className="lg:h-56 p-2 lg:px-3 border border-neutral-gray3"
    >
      <a
        href={url}
        aria-label={`${publishDate}: ${header}`}
        className="h-full flex flex-col justify-between group hover:text-primary-blue transition-color duration-300"
      >
        <div>
          <div className="pb-1 text-14 text-neutral-gray2">{publishDate}</div>
          <h3 className="h3">{header}</h3>
        </div>
        <div className="text-primary-blue group-hover:underline">
          <Trans i18nKey="readmore" />
        </div>
      </a>
    </Banner>
  </li>
);

interface NewsCarouselProps {
  data: ShopHpNewsCarouselEntity;
}

/**
 * Carousel s novinkami
 * @var header Nadpis celé sekce
 * @var link Odkaz vedle nadpisu
 * @var news Pole novinek (text, datum publikování, url)
 */
const NewsCarousel: React.FC<NewsCarouselProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { header, link, news } = data;
  const visibleNews = (news || []).filter(({ visibility }) =>
    isVisible(visibility?.visibleFrom, visibility?.visibleTo),
  );

  return (
    <section className="mt-4 lg:mt-5 mb-5 lg:mb-10">
      {/**
       * Řádek s nadpisem a odkazem (desktop)
       */}
      <div className="mb-2 flex justify-between items-center">
        {header && <h2 className="h1">{header}</h2>}
        {link && (
          <a
            href={link.url}
            className="sm:hidden flex items-center text-primary-blue hover:underline"
          >
            {link.text}
            <ChevronRight className="w-2 h-2 fill-current" />
          </a>
        )}
      </div>

      {/**
       * Carousel
       */}
      {!!visibleNews?.length && (
        <ul className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          {visibleNews.map(({ id, url, header: itemHeader, visibility }, i) => (
            <NewsBox
              key={id}
              orderIndex={i}
              publishDate={formatTime(visibility?.visibleFrom, 'DATE')}
              header={itemHeader}
              url={url}
            />
          ))}
        </ul>
      )}

      {/**
       * Odkaz (mobile)
       */}
      {link && (
        <a
          href={link.url}
          className="lg:hidden mt-3 flex items-center text-primary-blue hover:underline"
        >
          {link.text}
          <ChevronRight className="w-2 h-2 fill-current" />
        </a>
      )}
    </section>
  );
};
export default NewsCarousel;
