import Link from 'next/link';
import React from 'react';

const DynamicLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({
  href,
  children,
  ...props
}) => {
  const isUrlAbsolute = /^https?:\/\//i.test(href);

  return isUrlAbsolute ? (
    <a href={href} {...props}>
      {children}
    </a>
  ) : (
    <Link href={href}>
      <a {...props}>{children}</a>
    </Link>
  );
};

export default DynamicLink;
