import { useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { RouteOverview } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import { dayDiff, formatConnectionTime } from '@/utils/dateTimeUtils';

interface TimeProps {
  timeOverview: Pick<RouteOverview, 'departureTime' | 'arrivalTime' | 'travelTime'>;
  soldOut?: boolean;
}

const Time: React.FC<TimeProps> = ({
  timeOverview: { departureTime, arrivalTime, travelTime },
  soldOut,
  children,
}) => {
  const { t } = useTranslation();
  const formattedDepartureTime = formatConnectionTime(departureTime);
  const formattedArrivalTime = formatConnectionTime(arrivalTime);
  const dayDifference = dayDiff(departureTime, arrivalTime);

  return (
    <div
      className={classNames(
        'flex flex-wrap items-center gap-y-0.5 gap-1.5',
        soldOut && 'text-neutral-gray2',
      )}
    >
      <h2
        className="h3"
        aria-label={`${t(
          'accessibility.connection.time',
        )} ${formattedDepartureTime} - ${formattedArrivalTime}`}
      >
        {formattedDepartureTime}
        {' - '}
        {formattedArrivalTime}
        {!!dayDifference && <sup>+{dayDifference}</sup>}
      </h2>
      <span
        className="text-13 lg:text-14"
        aria-label={t('accessibility.transfertime', { time: travelTime })}
      >
        {travelTime}
      </span>
      {children}
    </div>
  );
};

export default Time;
