import React from 'react';
import ArticleBox from '@/components/content/ArticleBox';
import { ChevronRight } from '@/icons';
import { ShopHpArticleCarouselEntity } from '@/models/types';
import { isVisible } from '@/utils/dateTimeUtils';

interface ArticleCarouselProps {
  data: ShopHpArticleCarouselEntity;
}

/**
 * Carousel s články
 * @var header Nadpis celé sekce
 * @var link Odkaz vedle nadpisu
 * @var article Pole článků (text, obrázek, url)
 */
const ArticleCarousel: React.FC<ArticleCarouselProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { header, link, article } = data;
  const visibleArticles = (article || []).filter(({ visibility }) =>
    isVisible(visibility?.visibleFrom, visibility?.visibleTo),
  );

  return (
    <section className="mt-4 lg:mt-5 mb-5 lg:mb-10">
      {/**
       * Řádek s nadpisem a odkazem (desktop)
       */}
      <div className="mb-2 flex justify-between items-center">
        {header && <h2 className="h1">{header}</h2>}
        {link && (
          <a
            href={link.url}
            className="sm:hidden flex items-center text-primary-blue hover:underline"
          >
            {link.text}
            <ChevronRight className="w-2 h-2 fill-current" />
          </a>
        )}
      </div>

      {/**
       * Carousel
       */}
      {!!visibleArticles?.length && (
        <ul className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          {visibleArticles.map(({ id, url, header: itemHeader, image }, i) => (
            <ArticleBox
              orderIndex={i}
              key={id}
              image={image}
              header={itemHeader}
              url={url}
              type="article"
            />
          ))}
        </ul>
      )}

      {/**
       * Odkaz (mobile)
       */}
      {link && (
        <a
          href={link.url}
          className="lg:hidden mt-3 flex items-center text-primary-blue hover:underline"
        >
          {link.text}
          <ChevronRight className="w-2 h-2 fill-current" />
        </a>
      )}
    </section>
  );
};

export default ArticleCarousel;
