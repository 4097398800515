import { Trans } from 'next-i18next';
import React from 'react';
import { Button } from '@/components/atoms/Button';

interface TariffNotificationProps {
  description: string;
  content: string[];
  onConfirm: () => void;
}
const TariffNotification: React.FC<TariffNotificationProps> = ({
  description,
  content,
  onConfirm,
}) => (
  <div>
    <p className="pb-1">{description}</p>
    {content.map((tariffs) => (
      <p className="my-1.5 text-secondary-bluedark2" key={tariffs}>
        {tariffs}
      </p>
    ))}
    <div className="flex justify-end">
      <Button size="big" className="sm:w-full" onClick={onConfirm}>
        <Trans i18nKey="ticket.passengerConfirmationModal.button.understand" />
      </Button>
    </div>
  </div>
);

export default TariffNotification;
