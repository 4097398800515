import React from 'react';
import classNames from 'classnames';
import { ButtonLink } from '@/components/atoms/Button';
import StrapiImage from '@/components/content/StrapiImage';
import PageIcon from '@/components/strapi/PageIcon';
import useMobileWidth from '@/hooks/useMobileWidth';
import {
  ComponentPageButton,
  ComponentPageIcon,
  Enum_Componentpageiconbox_Iconcolor,
  ShopHpImportantInfoEntity,
} from '@/models/types';
import { mapBackgroundToIcon, mapColorToIcon } from '@/utils/strapiUtils';

interface ImportantInfoBoxProps {
  icon: ComponentPageIcon;
  iconColor: Enum_Componentpageiconbox_Iconcolor;
  header: string;
  button: ComponentPageButton;
}
const ImportantInfoBox: React.FC<ImportantInfoBoxProps> = ({ icon, iconColor, header, button }) => {
  const background = mapBackgroundToIcon[iconColor];
  const color = mapColorToIcon[iconColor];

  const { text, url } = button;
  return (
    <div className="flex p-2 lg:px-3 lg:py-4 border border-neutral-graylight3 rounded-sm">
      {icon && (
        <div
          className={classNames(
            'w-12 h-12 mr-2 flex justify-center items-center rounded-full flex-shrink-0',
            background,
            color,
          )}
        >
          <PageIcon data={icon} className="w-7 h-7" />
        </div>
      )}
      <div>
        {header && <h3 className="h4 mb-0.5 leading-tight">{header}</h3>}
        <a href={url} className="text-primary-blue hover:text-black hover:underline">
          {text}
        </a>
      </div>
    </div>
  );
};

interface ImportantInfoProps {
  data: ShopHpImportantInfoEntity;
}

/**
 * Důležité informace
 * @var image Obrázek s mapou linek (na pozadí sekce)
 */
const ImportantInfo: React.FC<ImportantInfoProps> = ({ data }) => {
  const { isMobile } = useMobileWidth();

  if (!data) {
    return null;
  }

  const {
    header,
    bannerHeader,
    bannerSubheader1,
    bannerSubheader2,
    bannerButton,
    image,
    smallBanner,
  } = data;

  const { url, urlMobile, text, textMobile } = bannerButton || {};

  return (
    <section className="mb-5 lg:mb-10">
      {header && <h2 className="h1 mb-2">{header}</h2>}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
        <div className="flex flex-col gap-2">
          {(smallBanner || []).map(
            ({ id, header: smallBoxHeader, iconColor, icon, button: smallBoxButton }) => (
              <ImportantInfoBox
                key={id}
                icon={icon}
                iconColor={iconColor}
                header={smallBoxHeader}
                button={smallBoxButton}
              />
            ),
          )}
        </div>
        <div
          className={classNames(
            'relative overflow-hidden lg:col-span-2 p-2 lg:px-4 lg:py-3 rounded-sm sm:bg-primary-bluelight3',
            !image && 'bg-primary-bluelight3',
          )}
        >
          {bannerHeader && <h3 className="h2 mb-0.5">{bannerHeader}</h3>}
          {(bannerSubheader1 || bannerSubheader2) && (
            <p className="mb-2 sm:text-14">
              {bannerSubheader1}
              <br /> {bannerSubheader2}
            </p>
          )}
          {bannerButton && (
            <ButtonLink href={isMobile ? urlMobile : url} variant="secondary" size="big">
              {isMobile ? textMobile : text}
            </ButtonLink>
          )}
          <div className="sm:relative sm:overflow-hidden sm:h-48 sm:mt-3 sm:-mx-2 sm:-mb-2">
            {image && (
              <div className="absolute -top-1/4 lg:top-0 -bottom-1/4 lg:bottom-0 left-0 right-0 sm:z-0 lg:z-background">
                <StrapiImage image={image} alt={bannerHeader} className="sm:object-right" />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportantInfo;
