import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import Banner from '@/components/atoms/Banner';
import { Button } from '@/components/atoms/Button';
import LoginBox from '@/components/login/LoginBox';
import useModal from '@/components/modal/useModal';

interface LoginBannerProps {
  className?: string;
}
const LoginBanner: React.FC<LoginBannerProps> = ({ className }) => {
  const { showModal } = useModal();

  const openModal = () => showModal(<LoginBox />, { size: 'big', removePadding: true });

  return (
    <Banner
      className={classNames(
        'bg-primary-bluelight rounded-sm p-2 lg:px-3 lg:py-1.5 sm:gap-2 sm:flex-col',
        className,
      )}
    >
      <div>
        <h3 className="h2">
          <Trans i18nKey="loginBox.loginColumn.header" />
        </h3>
        <p>
          <Trans i18nKey="loginBox.loginColumn.paragraph" />
        </p>
      </div>
      <Button variant="secondary" size="big" onClick={openModal} className="sm:w-full">
        <Trans i18nKey="settings.mojeid.login" />
      </Button>
    </Banner>
  );
};

export default LoginBanner;
