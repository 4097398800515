import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useConnection, useLocations } from '@web/shop-logic';
import { ConnectionPayload } from '@web/shop-logic/dist/hooks/useConnection';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import BoxLoader from '@/components/loader/BoxLoader';
import { ArrowEast } from '@/icons';
import { groupBy } from '@/utils/arrayUtils';

interface ConnectionAlternativeProps {
  payload: Omit<ConnectionPayload, 'id'>;
  withoutTransfer?: boolean;
}

const ConnectionAlternative: React.FC<ConnectionAlternativeProps> = ({
  payload,
  withoutTransfer,
}) => {
  const { getStation, getCity } = useLocations();
  const { push } = useRouter();
  const { fetchConnection, data: connectionData, loading } = useConnection();

  const stationFrom = getStation(payload.fromLocationId);

  const stationToName =
    payload.toLocationType === 'CITY'
      ? getCity(payload.toLocationId)?.name
      : getStation(payload.toLocationId)?.fullname;

  const alternatives = [...groupBy(connectionData?.routes, (r) => r.departureStationId)].filter(
    ([stationId, connection]) =>
      stationId !== payload.fromLocationId &&
      (withoutTransfer ? connection.some((c) => !c.transfersCount) : true),
  );

  useEffect(() => {
    if (stationFrom?.id || stationFrom?.cityId) {
      fetchConnection({
        ...payload,
        fromLocationId: stationFrom?.cityId || stationFrom?.id,
        fromLocationType: 'CITY',
      });
    }
  }, [stationFrom?.id, stationFrom?.cityId]);

  return loading ? (
    <div>
      <h2 className="h2 mt-3">
        <Trans i18nKey="route.alternative.search" />
      </h2>
      <div className="my-2">
        <BoxLoader />
      </div>
    </div>
  ) : (
    !!alternatives.length && (
      <div>
        <h2 className="h2 mt-3">
          <Trans i18nKey={withoutTransfer ? 'route.alternative.noTransfer' : 'route.alternative'} />
        </h2>
        {alternatives.map(([stationId]) => {
          const stationFromName = getStation(stationId)?.fullname;
          return (
            <ClickableDiv
              onClick={() => push({ query: { ...payload, fromLocationId: stationId } })}
              key={stationId}
              className="flex flex-wrap items-center gap-1 mt-2 text-primary-blue hover:underline"
              gtmName={`Connection alternation${withoutTransfer && ' without transfer'}`}
            >
              {stationFromName} <ArrowEast className="w-2 h-2 fill-current" /> {stationToName}
            </ClickableDiv>
          );
        })}
      </div>
    )
  );
};

export default ConnectionAlternative;
