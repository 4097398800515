import DynamicLink from '../atoms/DynamicLink';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import Alert, { AlertVariants } from '@/components/atoms/Alert';
import { ComponentShopAlert } from '@/models/types';
import { isVisible } from '@/utils/dateTimeUtils';

interface HpAlertProps {
  data: ComponentShopAlert;
  variant?: `${AlertVariants}`;
}

const HpAlert: React.FC<HpAlertProps> = ({ data, variant }) => {
  if (!data) return null;

  const { text, visibility } = data;
  const showAlert = isVisible(visibility?.visibleFrom, visibility?.visibleTo);

  return text && showAlert ? (
    <Alert variant={variant} className="wysiwyg mb-3">
      <ReactMarkdown
        components={{
          a: ({ href, ...props }) => <DynamicLink href={href} {...props} />,
        }}
      >
        {text}
      </ReactMarkdown>
    </Alert>
  ) : null;
};

export default HpAlert;
