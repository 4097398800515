import React from 'react';
import classNames from 'classnames';

const stripeClasses = 'card-skeleton-stripe bg-neutral-gray3';

/**
 * Skeleton pro komponentu `<ConnectionCard>`
 */
const ConnectCardSkeleton: React.FC = () => (
  <div className="card-skeleton-box relative overflow-hidden mb-2 px-2 sm:pr-1.5 py-2 rounded-sm border border-neutral-gray3">
    <div className="flex justify-between items-center mb-0.5">
      <div className={classNames(stripeClasses, 'w-1/3 h-7')} />
      <div className={classNames(stripeClasses, 'w-10 h-9')} />
    </div>
    <div className={classNames(stripeClasses, 'w-1/2 lg:w-5/12 h-6')} />
  </div>
);

export default ConnectCardSkeleton;
