import { Trans } from 'next-i18next';
import React from 'react';
import { Button } from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { ShopHpSmartemailingEntity } from '@/models/types';

interface SmartEmailingProps {
  data: ShopHpSmartemailingEntity;
}

/**
 * Smart Emailing - HTML z externí služby
 */
const SmartEmailing: React.FC<SmartEmailingProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { show } = data;

  return show ? (
    <section className="sm:pt-2 sm:pb-4 lg:py-4 bg-primary-yellow">
      <div className="page-layout grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div>
          <h2 className="h2 lg:w-4/5">
            <Trans i18nKey="footer.newsletter.header" />
          </h2>
        </div>
        <form
          target="_top"
          method="post"
          id="se-webform-9"
          className="h-full flex sm:flex-col lg:items-center gap-2 lg:gap-1"
          action="https://app.smartemailing.cz/public/web-forms-v2/display-form/271776-7hlfq3t9o484n6rio0ddv6ivolxg0amd4c3bpoe8n5f63h1d2kd9ludv46nm0jtdmhc9ozjum7004q0kbstxkbi1hmf5kveatq88"
        >
          <Input
            label="Váš e-mail"
            className="w-full bg-white rounded-sm"
            // Disable required-asterisk in label component
            InputLabelProps={{ required: false }}
            // Props from external HTML
            type="text"
            name="fields[df_emailaddress]"
            data-emailaddress="1"
            id="frm-webFormHtmlRenderer-webFormForm-fields-df_emailaddress"
            required
          />
          <Button size="big" type="submit" name="_submit" className="sm:w-full flex-shrink-0">
            <Trans i18nKey="footer.newsletter.button" />
          </Button>

          <input type="hidden" name="referrer" id="se-ref-field-id" value="" />
          <input type="hidden" name="sessionid" id="se-sessionid-field" value="" />
          <input type="hidden" name="sessionUid" id="se-sessionUid-field" value="" />
          <input type="hidden" name="_do" value="webFormHtmlRenderer-webFormForm-submit" />
        </form>
      </div>
    </section>
  ) : null;
};

export default SmartEmailing;
