import { Button, ButtonLink } from '../atoms/Button';
import ModalContext from '../modal/ModalContext';
import { Trans } from 'next-i18next';
import React from 'react';
import useContextSelector from '@/hooks/useContextSelector';

interface SeatClassModalProps {
  description: string;
  url: string;
}

const SeatClassModal: React.FC<SeatClassModalProps> = ({ description, url }) => {
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);

  return (
    <div className="flex flex-col gap-2">
      <p>{description}</p>

      <ButtonLink
        href={url}
        size="big"
        variant="secondary"
        className="w-full"
        onClick={hideAllModals}
      >
        <Trans i18nKey={'moreinfo'} />
      </ButtonLink>

      <Button size="big" className="w-full" onClick={hideAllModals}>
        <Trans i18nKey={'understand'} />
      </Button>
    </div>
  );
};

export default SeatClassModal;
