import React from 'react';
import StrapiImage from '@/components/content/StrapiImage';
import { ChevronRight, DirectionsBus, DirectionsTrain, PlaneTicket } from '@/icons';
import { ComponentShopTicketLink, ShopHpTicketCarouselEntity, UploadFile } from '@/models/types';
import { isVisible } from '@/utils/dateTimeUtils';

const TicketBoxLink: React.FC<Omit<ComponentShopTicketLink, 'id'>> = ({ text, url, type }) => {
  const mapIconToType = {
    bus: DirectionsBus,
    train: DirectionsTrain,
    plane: PlaneTicket,
  };
  const Icon = mapIconToType[type];

  return (
    <div className="flex items-center text-neutral-gray">
      <Icon className="w-2 h-2 mr-1 fill-current" />
      <a href={url} rel="nofollow" className="text-14 hover:underline">
        {text}
      </a>
    </div>
  );
};

interface TicketBoxProps {
  city: string;
  image: Pick<UploadFile, 'url' | 'width' | 'height' | 'formats' | 'name'>;
  links: ComponentShopTicketLink[];
}
/**
 * Box pro odkaz obsahující obrázek, město a odkazy na jízdenky
 */
const TicketBox: React.FC<TicketBoxProps> = ({ city, image, links }) => (
  <li className="flex items-start">
    <div className="overflow-hidden w-32 h-20 mr-2.5 rounded-sm flex-shrink-0">
      <StrapiImage alt={city} image={image} aria-hidden />
    </div>
    <div>
      <h3 className="h4 mb-0.5">{city}</h3>
      {(links || []).map(({ id, text, url, type }) => (
        <TicketBoxLink key={id} type={type} text={text} url={url} />
      ))}
    </div>
  </li>
);

interface TicketCarouselProps {
  data: ShopHpTicketCarouselEntity;
}

/**
 * Carousel s jízdenkami
 * @var header Nadpis celé sekce
 * @var link Odkaz vedle nadpisu
 * @var tickets Pole jízdenek (město, jízdenky na bus/vlak/letadlo (text, odkaz))
 */
const TicketCarousel: React.FC<TicketCarouselProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { header, tickets, link } = data;

  return (
    <section className="mt-4 lg:mt-5 mb-5 lg:mb-10">
      {/**
       * Řádek s nadpisem a odkazem (desktop)
       */}
      <div className="mb-2 flex justify-between items-center">
        {header && <h2 className="h1">{header}</h2>}
        {link && (
          <a
            href={link.url}
            className="sm:hidden flex items-center text-primary-blue hover:underline"
          >
            {link.text}
            <ChevronRight className="w-2 h-2 fill-current" />
          </a>
        )}
      </div>

      {/**
       * Carousel
       */}
      {!!(tickets || []).length && (
        <ul className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          {(tickets || []).map(({ id, city, image, links, visibility }) => {
            const showArticle = isVisible(visibility?.visibleFrom, visibility?.visibleTo);
            return showArticle ? (
              <TicketBox key={id} city={city} image={image} links={links} />
            ) : null;
          })}
        </ul>
      )}

      {/**
       * Odkaz (mobile)
       */}
      {link && (
        <a
          href={link.url}
          className="lg:hidden mt-3 flex items-center text-primary-blue hover:underline"
        >
          {link.text}
          <ChevronRight className="w-2 h-2 fill-current" />
        </a>
      )}
    </section>
  );
};
export default TicketCarousel;
