import Alert from '../atoms/Alert';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import {
  useBookingActions,
  useConnectionRoute,
  useTimeTickets,
  useUser,
  utils,
} from '@web/shop-logic';
import { SelectRoutePayload } from '@web/shop-logic/dist/store/booking/types';
import { Button } from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';
import Price from '@/components/atoms/Price';
import ConnectCardSkeleton from '@/components/loader/ConnectCardSkeleton';
import LoginBox from '@/components/login/LoginBox';
import useModal from '@/components/modal/useModal';
import RegistrationBox from '@/components/registration/RegistrationBox';
import URLS from '@/constants/url';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ArrowEast } from '@/icons';
import { isQueryValid } from '@/utils/routerUtils';

interface TimeTicketCardProps {
  routePayload: Omit<SelectRoutePayload, 'direction'>;
}

const TimeTicketCard: React.FC<TimeTicketCardProps> = ({ routePayload }) => {
  const { push, query } = useRouter();
  const { t } = useTranslation();
  const { isMobile } = useMobileWidth();
  const { isLoggedIn, isCreditPrice } = useUser();
  const { showModal } = useModal();
  const { data, fetchRoute, loading } = useTimeTickets();
  const { fetchRoute: fetchConnection, data: routeData, routeLoading } = useConnectionRoute();

  const { selectClass, selectRoute } = useBookingActions();

  useEffect(() => {
    fetchRoute(routePayload);
    fetchConnection(routePayload);
  }, []);

  return (
    <Card className="mb-4 sm:p-2" title={t('timeTicket.general.title')}>
      <div className="text-neutral-gray -mt-1 mb-2">
        <Trans i18nKey="timeTicket.general.info" />
      </div>
      <Formik initialValues={{ selectedFlexiTicket: 'WEEK' }} onSubmit={undefined}>
        {({ values, setValues }) => (
          <Form>
            <div className="flex flex-wrap gap-0.5 items-center mb-1.5">
              {isMobile &&
                data.map(({ flexiType }) => (
                  <Button
                    key={flexiType}
                    size="small"
                    variant="secondary"
                    onClick={() => setValues({ selectedFlexiTicket: flexiType })}
                  >
                    <Trans i18nKey={`ticket.flexiTypes.${flexiType}`} />
                  </Button>
                ))}
            </div>
            <div className="grid lg:grid-cols-3 gap-4">
              {(loading || routeLoading) && (
                <>
                  <ConnectCardSkeleton />
                  <ConnectCardSkeleton />
                  <ConnectCardSkeleton />
                </>
              )}
              {!(loading || routeLoading) && (
                <>
                  {/* Nepodařilo se načíst jízdenky  */}
                  {!routeData ? (
                    <Alert
                      variant="warning"
                      className="col-span-full"
                      headline={t('timeTickets.error.title')}
                    >
                      <Trans i18nKey={t('timeTickets.error.description')} />
                    </Alert>
                  ) : (
                    data.map(
                      ({ flexiType, lineGroupCode, lineNumber, price, seatClassKey, type }) => {
                        const handleSelectTimeTicket = async () => {
                          selectRoute({
                            ...routePayload,
                            tariffs: [routePayload.tariffs[0]],
                            type: 'FLEXI',
                          });
                          selectClass({
                            flexiType,
                            lineGroupCode,
                            lineNumber,
                            price,
                            seatClass: seatClassKey,
                            sections: utils.mapFromToSections(routeData.sections),
                            type,
                          });
                          await push(URLS.RESERVATION.PASSENGERS);
                        };

                        const handleTicketRedirect = async () => {
                          if (!isLoggedIn) {
                            showModal(<LoginBox creditOnly onLogin={handleSelectTimeTicket} />, {
                              size: 'big',
                              removePadding: true,
                            });
                          }
                          if (isLoggedIn && !isCreditPrice) {
                            showModal(<RegistrationBox />, { removePadding: true });
                          }
                          if (isCreditPrice && isQueryValid(query, 'search'))
                            await handleSelectTimeTicket();
                        };

                        return (
                          <div
                            key={flexiType}
                            className={classNames(
                              'relative overflow-hidden',
                              values.selectedFlexiTicket !== flexiType && 'sm:hidden',
                            )}
                          >
                            <div className="rounded-sm border border-neutral-gray3 bg-neutral-graylight">
                              <div className="relative p-2 border-b border-neutral-gray3 border-dashed">
                                <div className="rjticket-decorator rjticket-decorator--bottom-left" />
                                <div className="rjticket-decorator rjticket-decorator--bottom-right" />
                                <h4 className="h4">
                                  <Trans i18nKey={`ticket.flexiTypes.${flexiType}`} />
                                </h4>
                                <div className="text-14">
                                  <Trans i18nKey={`PricedSeatClass.${seatClassKey}`} />
                                </div>
                                <div className="flex gap-0.5 items-center flex-wrap text-14">
                                  {routeData.departureCityName}
                                  <ArrowEast className="w-2 h-2 flex-shrink-0 fill-current" />
                                  {routeData.arrivalCityName}
                                </div>
                              </div>
                              <div className="p-2 text-center">
                                <Button
                                  className="w-full my-1"
                                  size="small"
                                  onClick={handleTicketRedirect}
                                >
                                  <Price price={price} />
                                </Button>
                                <div className="text-12 text-neutral-gray">
                                  <Trans i18nKey={`tariff.${routePayload.tariffs[0]}`} />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      },
                    )
                  )}
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default TimeTicketCard;
