import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { IconElement, Seat, Time, TrainTicket } from '@/icons';

interface WhyUsBoxProps {
  Icon: IconElement;
  iconClasses: string;
  header: string;
}
const WhyUsBox: React.FC<WhyUsBoxProps> = ({ Icon, iconClasses, header }) => (
  <div className="flex items-center gap-2">
    <div
      className={classNames(
        'w-5 h-5 flex justify-center items-center rounded-full flex-shrink-0',
        iconClasses,
      )}
    >
      <Icon className="w-2.5 h-2.5 fill-current" />
    </div>
    <div>{header}</div>
  </div>
);

/**
 * Proč jezdit s námi
 */
const WhyUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="mb-5 lg:mb-10">
      <h2 className="h1 mb-2">
        <Trans i18nKey="whyus.header" />
      </h2>
      <div className="grid lg:grid-cols-3 gap-2">
        <WhyUsBox
          Icon={Seat}
          iconClasses="text-secondary-green bg-secondary-greenlight2"
          header={t('whyus.freeseat')}
        />
        <WhyUsBox
          Icon={TrainTicket}
          iconClasses="text-primary-yellow bg-primary-yellowlight3"
          header={t('whyus.storno')}
        />
        <WhyUsBox
          Icon={Time}
          iconClasses="text-primary-blue bg-primary-bluelight2"
          header={t('whyus.warranty')}
        />
      </div>
    </section>
  );
};

export default WhyUs;
