import React from 'react';
import ArticleBox from '@/components/content/ArticleBox';
import { ShopHpTripHintEntity } from '@/models/types';
import { isVisible } from '@/utils/dateTimeUtils';

interface TripHintsProps {
  data: ShopHpTripHintEntity;
}

/**
 * Tipy na výlety
 * @var header Nadpis celé sekce
 * @var article Pole článků (text, obrázek, url)
 */
const TripHints: React.FC<TripHintsProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { header, article } = data;
  const visibleArticles = (article || []).filter(({ visibility }) =>
    isVisible(visibility?.visibleFrom, visibility?.visibleTo),
  );

  return (
    <section className="mb-5 lg:mb-10">
      {header && <h2 className="h1 mb-2">{header}</h2>}
      {!!visibleArticles?.length && (
        <ul className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          {visibleArticles.map(({ id, url, header: itemHeader, image }, i) => (
            <ArticleBox
              key={id}
              header={itemHeader}
              image={image}
              orderIndex={i}
              type="trip-hint"
              url={url}
            />
          ))}
        </ul>
      )}
    </section>
  );
};

export default TripHints;
