import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { Person } from '@/icons';

export interface FreeSeatsProps extends React.HTMLProps<HTMLDivElement> {
  freeSeatsCount?: number;
  withSuffix?: boolean;
  offerFlexiOnSoldOut?: boolean;
}

const FreeSeats: React.FC<FreeSeatsProps> = ({
  freeSeatsCount = 0,
  offerFlexiOnSoldOut,
  withSuffix = false,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'flex items-center text-13 lg:text-14 flex-wrap',
        freeSeatsCount >= 10 && 'text-secondary-green',
        !!freeSeatsCount && freeSeatsCount < 10 && 'text-secondary-orange',
        !freeSeatsCount && 'text-secondary-redwarn',
        className,
      )}
    >
      <span className="sr-only">
        <Trans i18nKey="accessibility.freeSeatsCount" values={{ count: freeSeatsCount }} />
      </span>
      <Person className="w-2.5 h-2.5 lg:w-3 lg:h-3 mr-0.5 fill-current" />
      {freeSeatsCount || offerFlexiOnSoldOut ? (
        <span aria-hidden>
          {freeSeatsCount}
          {withSuffix && `\u00a0${t('price.freeSeats')}`}
        </span>
      ) : (
        t('connections.soldOut')
      )}
    </div>
  );
};

export default FreeSeats;
