import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { VehicleType } from '@web/shop-logic/dist/hooks/types';
import Chevron from '@/components/atoms/Chevron';
import VehicleTypeIcon from '@/components/atoms/VehicleTypeIcon';

const iconsClasses = 'w-2.5 h-2.5 lg:w-3 lg:h-3 fill-current';

export interface TransferProps {
  transferCount: number;
  vehicleTypes: VehicleType[];
  soldOut?: boolean;
  cardOpen?: boolean;
}

const Transfer: React.FC<TransferProps> = ({ transferCount, vehicleTypes, soldOut, cardOpen }) => (
  <div
    className={classNames(
      'flex items-center',
      soldOut ? 'text-neutral-gray2' : 'text-primary-blue',
    )}
  >
    <Chevron isOpened={cardOpen} />
    {vehicleTypes.map((vt) => (
      <VehicleTypeIcon key={vt} className={iconsClasses} vehicleType={vt} />
    ))}
    <span className="text-13 lg:text-14 pl-0.5">
      <Trans
        i18nKey={transferCount > 0 ? 'connection.transfers' : 'new.index.connection.direct'}
        values={{ count: transferCount }}
      />
    </span>
  </div>
);
export default Transfer;
