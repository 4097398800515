import { useRouter } from 'next/router';
import React from 'react';
import { UrlObject } from 'url';
import { useBookingActions } from '@web/shop-logic';
import { Tariff } from '@web/shop-logic/dist/hooks/types';
import { TariffNotifications } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import { ConnectionDirection } from '@web/shop-logic/dist/store/booking/types';
import TariffNotification from '@/components/fare/TariffNotification';
import useModal from '@/components/modal/useModal';

const useTariffNotification = (direction: ConnectionDirection) => {
  const { replaceTariffs } = useBookingActions(direction);
  const { showModal } = useModal();
  const { push } = useRouter();

  const onModalClose = async (tariffs: Tariff[], urlObject: UrlObject) => {
    replaceTariffs(tariffs);
    await push(urlObject);
  };

  const handleTariffNotification = (
    { description, title, content }: TariffNotifications,
    newTariffs: Tariff[],
    redirectObject: UrlObject,
  ) =>
    showModal(
      <TariffNotification
        description={description}
        content={content}
        onConfirm={() => onModalClose(newTariffs, redirectObject)}
      />,
      { onClose: () => onModalClose(newTariffs, redirectObject), title },
    );

  return { handleTariffNotification };
};

export default useTariffNotification;
