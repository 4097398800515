import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import MoreInfoButton from '@/components/atoms/MoreInfoButton';

const mapClassToRows = {
  0: '',
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
};

interface ClampProps {
  /**
   * Počet řádků, na který se má oříznout textový obsah
   */
  rows?: number;
}

/**
 * Ořez textového obsahu na určitý počet řádků (pouze přes CSS)
 * Pod text vykreslí přepínací tlačítko
 */
const Clamp: React.FC<ClampProps> = ({ children, rows = 2 }) => {
  const elementRef = useRef(null);

  const [isClamped, setIsClamped] = useState(false);
  const [removeClamp, setRemoveClamp] = useState(false);

  // Počítá line-clamp porovnáním původní a vykreslené výšky elementu
  const multiLineOverflows = (el) => el?.scrollHeight > el?.clientHeight;

  useEffect(() => {
    // Po renderu zjišťuje, jestli je aktivní line-clamp
    const result = multiLineOverflows(elementRef.current);
    setIsClamped(result);
  }, [children, rows]);

  return (
    <>
      <span ref={elementRef} className={classNames(!removeClamp && mapClassToRows[rows])}>
        {children}
      </span>
      {isClamped && (
        <MoreInfoButton onToggle={setRemoveClamp} className="inline-flex text-14 underline" />
      )}
    </>
  );
};

export default Clamp;
