import { parseISO, startOfDay, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { GetStaticProps } from 'next';
import ConnectionResults from '@/components/connection/ConnectionResults';
import ArticleCarousel from '@/components/content/ArticleCarousel';
import HpAlert from '@/components/content/HpAlert';
import ImportantInfo from '@/components/content/ImportantInfo';
import MainBanner from '@/components/content/MainBanner';
import NewsCarousel from '@/components/content/NewsCarousel';
import Services from '@/components/content/Services';
import SmartEmailing from '@/components/content/SmartEmailing';
import TicketCarousel from '@/components/content/TicketCarousel';
import TripHints from '@/components/content/TripHints';
import WhyUs from '@/components/content/WhyUs';
import Vikendo from '@/components/content/vikendo/Vikendo';
import ImageRow from '@/components/strapi/ImageRow';
import SecondaryBanner from '@/components/strapi/SecondaryBanner';
import Page from '@/components/templates/Page';
import { revalidate } from '@/constants/next';
import {
  ShopHpImportantInfoEntity,
  ShopHpNewsCarouselEntity,
  ShopHpServiceEntity,
  ShopHpTicketCarouselEntity,
  ShopHpTripHintEntity,
  ShopHpVikendoEntity,
  ShopHpSmartemailingEntity,
  ShopHpMainBannerEntity,
  ShopActionPriceEntity,
  ShopHpAlertEntity,
  ShopHpArticleCarouselEntity,
  ShopFaresImageEntity,
  EarlyBookingInfo,
  ShopHpFavoriteConnection,
  ShopHpSecondaryBannerEntity,
  ShopHpImageRowEntity,
} from '@/models/types';
import { formatTime } from '@/utils/dateTimeUtils';
import { getStrapiCollection, getStrapiSingleType } from '@/utils/fetchUtils';
import { getSharedProps } from '@/utils/pagesUtils';
import { buildQuery, isQueryValid } from '@/utils/routerUtils';

const SearchBox = dynamic(() => import('@/components/templates/SearchBox'), { ssr: false });

const isDateInPast = (date) => {
  const parsedDate = parseISO(date);
  return isBefore(parsedDate, startOfDay(utcToZonedTime(new Date(), 'Europe/Prague')));
};

interface IndexProps {
  alertData: ShopHpAlertEntity;
  articleCarouselData: ShopHpArticleCarouselEntity;
  mainBannerData: ShopHpMainBannerEntity;
  faresImagesData: ShopFaresImageEntity[];
  importantInfoData: ShopHpImportantInfoEntity;
  newsCarouselData: ShopHpNewsCarouselEntity;
  servicesData: ShopHpServiceEntity;
  smartEmailingData: ShopHpSmartemailingEntity;
  ticketCarouselData: ShopHpTicketCarouselEntity;
  tripHintsData: ShopHpTripHintEntity;
  vikendoData: ShopHpVikendoEntity;
  actionPricesData: ShopActionPriceEntity;
  favoriteConnectionsData: ShopHpFavoriteConnection;
  earlyBookingInfos: EarlyBookingInfo[];
  secondaryBannerData: ShopHpSecondaryBannerEntity;
  imageRowData: ShopHpImageRowEntity;
}

export const Index: React.FC<IndexProps> = ({
  alertData,
  articleCarouselData,
  mainBannerData,
  faresImagesData,
  importantInfoData,
  newsCarouselData,
  servicesData,
  smartEmailingData,
  ticketCarouselData,
  tripHintsData,
  vikendoData,
  actionPricesData,
  favoriteConnectionsData,
  earlyBookingInfos,
  secondaryBannerData,
  imageRowData,
}) => {
  const { query, replace } = useRouter();

  const showConnectionResults = isQueryValid(query, 'search');
  const [sroTicketsFetching, setSroTicketsFetching] = useState<boolean>(false);
  const [routesLoading, setRoutesLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isQueryValid(query, 'links') && !showConnectionResults) {
      replace({
        query: buildQuery({
          tariffs: 'REGULAR',
          ...query,
          departureDate: query.departureDate || formatTime(new Date(), 'YEAR-MONTH-DAY'),
        }),
      });
    }
  }, [query]);

  useEffect(() => {
    if (showConnectionResults && isDateInPast(query.departureDate)) {
      const currentDate = formatTime(new Date(), 'YEAR-MONTH-DAY');

      if (query.returnDepartureDate && isDateInPast(query.returnDepartureDate)) {
        {
          replace({
            query: buildQuery({
              ...query,
              departureDate: currentDate,
              returnDepartureDate: currentDate,
            }),
          });
        }
      } else {
        replace({
          query: buildQuery({ ...query, departureDate: currentDate }),
        });
      }
    }
  }, [showConnectionResults]);

  return showConnectionResults ? (
    /* Výsledky vyhledávání */
    <>
      <SearchBox
        strapiFavorites={favoriteConnectionsData?.favoriteConnections}
        setSroTicketsFetching={setSroTicketsFetching}
        className="mb-2.5 lg:my-3 pt-1 lg:px-1 lg:py-1.5"
        showCompactBox
        loading={routesLoading}
      />

      <Page>
        <ConnectionResults
          sroTicketsFetching={sroTicketsFetching}
          setSroTicketsFetching={setSroTicketsFetching}
          actionPricesColors={actionPricesData?.actionPrices}
          setRoutesLoading={setRoutesLoading}
          faresImagesData={faresImagesData}
          earlyBookingInfos={earlyBookingInfos}
        />
      </Page>
    </>
  ) : (
    /* Homepage */
    <>
      <MainBanner data={mainBannerData} />
      <SearchBox
        strapiFavorites={favoriteConnectionsData?.favoriteConnections}
        setSroTicketsFetching={setSroTicketsFetching}
        className="lg:w-full mb-3 lg:px-2.5 pt-2 lg:py-4"
        layoutClassName="lg:-mt-14"
      />
      <Page className="sm:pb-2 lg:pb-0">
        {(alertData?.alert || []).map((alert) => (
          <HpAlert key={alert.id} data={alert} variant={alert.variant} />
        ))}
        <ArticleCarousel data={articleCarouselData} />
        <SecondaryBanner data={secondaryBannerData} />
        <ImageRow component={imageRowData?.imageRow} removeLayoutClasses />
        <ImportantInfo data={importantInfoData} />
        <TicketCarousel data={ticketCarouselData} />
        <Vikendo data={vikendoData} />
        <TripHints data={tripHintsData} />
        <Services data={servicesData} />
        <NewsCarousel data={newsCarouselData} />
        <WhyUs />
      </Page>
      <SmartEmailing data={smartEmailingData} />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const [
    alertData,
    articleCarouselData,
    mainBannerData,
    faresImagesData,
    importantInfoData,
    newsCarouselData,
    servicesData,
    smartEmailingData,
    ticketCarouselData,
    tripHintsData,
    vikendoData,
    actionPricesData,
    favoriteConnectionsData,
    earlyBookingInfos,
    secondaryBannerData,
    imageRowData,
  ] = await Promise.all([
    getStrapiSingleType('shop-hp-alert', locale),
    getStrapiSingleType('shop-hp-article-carousel', locale),
    getStrapiSingleType('shop-hp-main-banner', locale),
    getStrapiSingleType('shop-fares-images', locale),
    getStrapiSingleType('shop-hp-important-info', locale),
    getStrapiSingleType('shop-hp-news-carousel', locale),
    getStrapiSingleType('shop-hp-service', locale),
    getStrapiSingleType('shop-hp-smartemailing', locale),
    getStrapiSingleType('shop-hp-ticket-carousel', locale),
    getStrapiSingleType('shop-hp-trip-hint', locale),
    getStrapiSingleType('shop-hp-vikendo', locale),
    getStrapiSingleType('shop-action-price', locale),
    getStrapiSingleType('shop-hp-favorite-connection', locale),
    getStrapiCollection('early-booking-infos', { locale, populate: 'deep' }),
    getStrapiSingleType('shop-hp-secondary-banner', locale),
    getStrapiSingleType('shop-hp-image-row', locale),
  ]);

  return {
    props: {
      alertData,
      articleCarouselData,
      mainBannerData,
      faresImagesData,
      importantInfoData,
      newsCarouselData,
      servicesData,
      smartEmailingData,
      ticketCarouselData,
      tripHintsData,
      vikendoData,
      actionPricesData,
      favoriteConnectionsData,
      earlyBookingInfos,
      secondaryBannerData,
      imageRowData,
      ...(await getSharedProps(locale)),
    },
    revalidate,
  };
};

export default Index;
