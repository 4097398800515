import { Button } from '../atoms/Button';
import Tooltip from '../atoms/Tooltip';
import SeatClassModal from '../fare/SeatClassModal';
import useModal from '../modal/useModal';
import PageIcon from '../strapi/PageIcon';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { types, useBookingActions, useConnectionRoute } from '@web/shop-logic';
import { utils } from '@web/shop-logic';
import { Tariff } from '@web/shop-logic/dist/hooks/types';
import { ConnectionRoute } from '@web/shop-logic/dist/hooks/useConnection';
import { ConnectionDirection, SelectRoutePayload } from '@web/shop-logic/dist/store/booking/types';
import Alert from '@/components/atoms/Alert';
import Badge, { SupportConnectionBadge } from '@/components/atoms/Badge';
import Banner from '@/components/atoms/Banner';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import FreeSeats from '@/components/atoms/FreeSeats';
import PriceButton from '@/components/atoms/PriceButton';
import Time from '@/components/atoms/Time';
import TrafficInterruption from '@/components/atoms/TrafficInterruption';
import Transfer from '@/components/atoms/Transfer';
import TicketHeaderBadge from '@/components/confirmationTicket/TicketHeaderBadge';
import SectionDetail, { SeatClassCount } from '@/components/connection/SectionDetail';
import TransferTime from '@/components/connection/TransferTime';
import BoxLoader from '@/components/loader/BoxLoader';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import {
  ActionPricesVariants,
  mapActionPriceBorderClasses,
  mapActionPriceMainClasses,
  mapActionPriceSecondaryClasses,
} from '@/constants/actionPricesConsts';
import env from '@/constants/env';
import URLS from '@/constants/url';
import useMobileWidth from '@/hooks/useMobileWidth';
import usePrice from '@/hooks/usePrice';
import useTariffNotification from '@/hooks/useTariffNotification';
import { Sale } from '@/icons';
import { ComponentShopActionPrices, ShopFaresImageEntity } from '@/models/types';
import { createParamArray, getFirstParamValue, isQueryValid } from '@/utils/routerUtils';
import { isUkTransfer } from '@/utils/ticketUtils';

interface ConnectCardProps {
  routeOverview: ConnectionRoute;
  isRjSro: boolean;
  offerFlexiOnSoldOut?: boolean;
  actionPricesColors?: ComponentShopActionPrices[];
  faresImagesData: ShopFaresImageEntity[];
}

const ConnectionCard: React.FC<ConnectCardProps> = ({
  routeOverview,
  isRjSro,
  offerFlexiOnSoldOut,
  actionPricesColors,
  faresImagesData,
}) => {
  const { t } = useTranslation();
  const { formatPrice } = usePrice();
  const { query, push } = useRouter();
  const { showModal } = useModal();
  const direction = getFirstParamValue(query.direction) as ConnectionDirection;
  const { selectRoute, selectClass } = useBookingActions(direction);
  const { isMobile } = useMobileWidth();
  const { handleTariffNotification } = useTariffNotification(direction);
  const { data, routeLoading, fetchRoute, routeError, fetchSroRoutes, rjSroClasses } =
    useConnectionRoute();

  const [openCard, setOpenCard] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [classSelected, setClassSelected] = useState(false);
  const cardRef = useRef(null);

  const tariffCount = createParamArray(query.tariffs)?.length || 0;

  const {
    actionPrice,
    arrivalStationId,
    arrivalTime,
    bookable,
    delay,
    departureStationId,
    departureTime,
    freeSeatsCount: routeFreeSeatsCount,
    id,
    notices,
    priceFrom,
    priceTo,
    pricesCount,
    support,
    surcharge,
    transfersCount,
    travelTime,
    vehicleStandards,
    vehicleTypes,
  } = routeOverview;

  const routePayload: Omit<SelectRoutePayload, 'direction'> = {
    routeId: id,
    fromStationId: departureStationId,
    toStationId: arrivalStationId,
    tariffs: createParamArray(query.tariffs) as Tariff[],
  };

  const priceClasses: SeatClassCount[] = ((isRjSro ? rjSroClasses : data?.priceClasses) || []).map(
    ({ seatClassKey, freeSeatsCount }) => ({ seatClassKey, freeSeatsCount }),
  );

  // if bookable is false the state is the same as no freeSeatsCount
  const freeSeatsCount = bookable === false ? 0 : routeFreeSeatsCount;
  const priceWithSurcharge = priceFrom + (data?.surcharge?.price || 0);

  const handleSelectRoute = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isQueryValid(query, 'search')) {
      selectRoute({ ...routePayload, type: isRjSro ? 'RJ_SRO' : undefined });
      if (pricesCount === 1 && !offerFlexiOnSoldOut && !isRjSro) {
        /* Nacteni trid pro presnerovani primo na stranku sedadel */
        const routeDetail = await fetchRoute(routePayload);
        if (
          routeDetail?.priceClasses &&
          routeDetail.priceClasses.length === 1 &&
          routeDetail.priceClasses[0].seatClassKey === 'NO'
        ) {
          setClassSelected(true);
          return;
        }
      }
      await push({
        pathname: `${URLS.RESERVATION.FARE}/${getFirstParamValue(query.direction || 'there')}`,
        query: { ...routePayload },
      });
    }
  };

  useEffect(() => {
    if (!data && query.tariffs && (openCard || surcharge)) {
      fetchRoute(routePayload);
      if (isRjSro) fetchSroRoutes(routePayload);
    }
  }, [query.tariffs, openCard, surcharge, data]);

  useEffect(() => {
    if (cardRef?.current && data && openCard)
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [data, cardRef, openCard]);

  useEffect(() => {
    /* Preskocit vyber tridy pokud je k dispozici jen jedna */
    const shouldSkipClassSelection =
      data?.priceClasses.length === 1 && data?.priceClasses[0].seatClassKey === 'NO';

    if (shouldSkipClassSelection && !redirecting && classSelected && !isRjSro) {
      selectClass({
        ...data.priceClasses[0],
        seatClass: data.priceClasses[0].seatClassKey,
        sections: utils.mapFromToSections(data.sections),
      });

      const redir = {
        pathname: `${URLS.RESERVATION.SEATING}/${getFirstParamValue(query.direction || 'there')}`,
        query: { ...routePayload, seatClassKey: data.priceClasses[0].seatClassKey },
      };

      if (data?.priceClasses[0].tariffNotifications) {
        handleTariffNotification(
          data?.priceClasses[0].tariffNotifications,
          data?.priceClasses[0].tariffs,
          redir,
        );
      } else {
        push(redir);
      }
      setRedirecting(true);
    }
  }, [data, redirecting, classSelected, isRjSro]);

  const sectionsNotices = data?.sections.flatMap((s) => s.notices || []);

  const openActionModal = () =>
    showModal(<SeatClassModal description={actionPrice?.description} url={actionPrice?.url} />, {
      title: t('actionPrice.badge'),
    });

  const correctActionPrice = actionPricesColors?.find((a) => a?.actionName === actionPrice?.name);
  const correctActionColor = correctActionPrice?.displayColor || ActionPricesVariants.GREEN;
  const correctActionIcon = correctActionPrice?.actionIcon;
  const correctActionBg =
    correctActionPrice?.backgroundImg?.url &&
    `${env.STRAPI_URL}${correctActionPrice.backgroundImg.url}`;

  return (
    <div>
      {isMobile && actionPrice?.showIcon && (
        <div
          onClick={openActionModal}
          className={classNames(
            mapActionPriceMainClasses[correctActionColor],
            'w-full flex justify-center rounded-t-sm cursor-pointer',
          )}
        >
          <Badge actionColor={correctActionColor} className="flex items-center">
            {correctActionIcon ? (
              <PageIcon data={correctActionIcon} className="fill-white w-2 h-2" />
            ) : (
              <Sale className="fill-white w-2 h-2" />
            )}
            <h2 className="font-medium">
              {correctActionIcon?.badgeTitle ? (
                correctActionIcon.badgeTitle
              ) : (
                <Trans i18nKey="actionPrice.badge" />
              )}
            </h2>
          </Badge>
        </div>
      )}
      <Banner
        style={
          correctActionBg && actionPrice?.showIcon
            ? { backgroundImage: `url(${correctActionBg})` }
            : undefined
        }
        className={classNames(
          'mb-2 px-2 sm:pr-1.5 pt-2 border',
          actionPrice?.showIcon
            ? `${mapActionPriceBorderClasses[correctActionColor]} ${mapActionPriceSecondaryClasses[correctActionColor]} border-2 rounded-none rounded-b-sm`
            : 'border-neutral-gray3 bg-white rounded-sm',
          actionPrice?.showIcon && !isMobile && 'rounded-t-sm',
        )}
      >
        {/* Hlavička - kliknutím se rozbalí detail */}
        <ClickableDiv
          className={classNames(
            'h-full pb-1.5 flex flex-col justify-between',
            openCard && 'border-neutral-gray3 border-b',
          )}
          gtmPlace="ConnectionCard"
          gtmName="Show detail"
          data-id={`connection-card-${id}`}
          onClick={() => setOpenCard(!openCard)}
        >
          {/* 1. řádek: Čas příjezdu a odjezdu + cena */}
          <div className="flex justify-between items-start">
            <Time
              timeOverview={{ travelTime, departureTime, arrivalTime }}
              soldOut={!freeSeatsCount}
            >
              {/* Action price - desktop badge */}
              {!isMobile && actionPrice?.showIcon && (
                <Tooltip placement="top" title={actionPrice?.description}>
                  <div>
                    <Link href={actionPrice?.url} passHref>
                      <Badge
                        actionColor={correctActionColor}
                        className="cursor-pointer flex items-center"
                      >
                        {correctActionIcon ? (
                          <PageIcon data={correctActionIcon} className="fill-white w-2 h-2" />
                        ) : (
                          <Sale className="fill-white w-2 h-2" />
                        )}
                        <h2 className="font-medium">
                          {correctActionIcon?.badgeTitle ? (
                            correctActionIcon.badgeTitle
                          ) : (
                            <Trans i18nKey="actionPrice.badge" />
                          )}
                        </h2>
                      </Badge>
                    </Link>
                  </div>
                </Tooltip>
              )}
              {/* Badge: Zpoždění */}
              {delay && !delay.startsWith('00:00') && (
                <Badge>
                  {isMobile ? '+' : <Trans i18nKey="delays.title" />} {delay}
                </Badge>
              )}
              {/* Badge: Místenka */}
              {isRjSro && <TicketHeaderBadge type="RJ_SRO" />}
              {/* Badge: Posilový spoj (desktop) */}
              {!isMobile && support && (
                <SupportConnectionBadge
                  clearTooltip={!vehicleStandards.includes(types.VehicleKey.ECONOMY)}
                />
              )}
            </Time>
            <div className="flex sm:flex-col-reverse items-center sm:items-end">
              {offerFlexiOnSoldOut && !freeSeatsCount && (
                <Button
                  onClick={handleSelectRoute}
                  data-id={`connection-card-price-${id}`}
                  className="whitespace-nowrap"
                >
                  {t('buy.ticket')}
                </Button>
              )}
              {!!freeSeatsCount && (
                <PriceButton
                  aria-hidden
                  onClick={handleSelectRoute}
                  data-id={`connection-card-price-${id}`}
                  className="whitespace-nowrap"
                  price={priceWithSurcharge}
                  hasFromPrefix={priceFrom !== priceTo}
                />
              )}
            </div>
          </div>
          {/* 2. řádek: Typ prostředku + volná místa */}
          <div className="flex items-center justify-between">
            <div className={classNames('flex gap-2', notices && 'sm:mt-1')}>
              <Transfer
                vehicleTypes={vehicleTypes}
                transferCount={transfersCount}
                soldOut={!freeSeatsCount}
                cardOpen={openCard}
              />
              <FreeSeats
                freeSeatsCount={freeSeatsCount}
                withSuffix={!isMobile}
                offerFlexiOnSoldOut={offerFlexiOnSoldOut}
              />
              {notices && <TrafficInterruption />}
              {/* Badge: Posilový spoj (mobil) */}
              {isMobile && support && (
                <SupportConnectionBadge
                  clearTooltip={!vehicleStandards.includes(types.VehicleKey.ECONOMY)}
                  enableMobileView
                />
              )}
            </div>
            {freeSeatsCount ? (
              <span
                className={classNames(
                  'sm:hidden text-13',
                  !freeSeatsCount ? 'text-neutral-gray2' : 'text-neutral-gray',
                )}
              >
                <Trans i18nKey="ticket.discount.passengers" />: {tariffCount}
              </span>
            ) : null}
          </div>
        </ClickableDiv>
        {/* Detail */}
        {routeLoading && openCard && <BoxLoader className="flex justify-center py-5" />}
        {openCard && data && (
          <ul ref={cardRef} aria-label={t('accessibility.ticket.detail')}>
            {sectionsNotices.map((notice) => (
              <div key={notice} className="mt-1">
                <Alert innerContentClasses="[&_a]:underline" variant="warning">
                  {notice}
                </Alert>
              </div>
            ))}
            {data.sections.map((sect, index) => {
              const validTransfer = data.transfersInfo?.transfers?.find(
                (trans) =>
                  trans.fromStationId === sect.arrivalStationId &&
                  trans.toStationId === data.sections[index + 1].departureStationId,
              );

              const info =
                validTransfer && isUkTransfer(sect, data.sections[index + 1])
                  ? t('transfer.UK.info')
                  : data?.transfersInfo?.info;

              return (
                <li key={sect.id}>
                  <SectionDetail
                    section={sect}
                    priceClasses={sect.id === data.mainSectionId && priceClasses}
                    isFirst={index === 0 && !sectionsNotices.length}
                    isLast={data.sections.length - 1 === index}
                    faresImagesData={faresImagesData}
                    firstDepartureTime={data.sections[0].departureTime}
                  />
                  {validTransfer && (
                    <TransferTime
                      transfer={validTransfer}
                      arrivalTime={sect.arrivalTime}
                      departureTime={data.sections[index + 1]?.departureTime}
                      info={info}
                    />
                  )}
                </li>
              );
            })}
            <li className="flex pb-3 justify-end">
              {bookable && (
                <PriceButton
                  aria-label={t('accessibility.confirm.ticket', {
                    price: formatPrice(priceWithSurcharge),
                  })}
                  className="mt-8"
                  onClick={handleSelectRoute}
                  price={priceWithSurcharge}
                  hasFromPrefix={priceFrom !== priceTo}
                />
              )}
            </li>
          </ul>
        )}
        {routeError?.response?.data?.message && !query?.isRouteInvalid && (
          <Alert variant="warning">{routeError.response.data.message}</Alert>
        )}
        <FullScreenLoader showLoader={!openCard && pricesCount === 1 && routeLoading} />
      </Banner>
    </div>
  );
};

export default ConnectionCard;
