import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import { ButtonLink } from '@/components/atoms/Button';
import StrapiImage from '@/components/content/StrapiImage';
import URLS from '@/constants/url';
import { ChevronRight } from '@/icons';
import { ShopHpServiceEntity } from '@/models/types';

interface ServicesBoxProps {
  text: string;
  url: string;
}
const ServicesLink: React.FC<ServicesBoxProps> = ({ text, url }) => (
  <li>
    <Link href={url}>
      <a className="py-0.5 flex items-center hover:underline">
        {text}
        <ChevronRight className="w-2 h-2 fill-current" />
      </a>
    </Link>
  </li>
);

interface ServicesProps {
  data: ShopHpServiceEntity;
}

/**
 * Bezkonkurenční servis
 * @var image Obrázek s mapou linek (na pozadí sekce)
 */
const Services: React.FC<ServicesProps> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const { image, links, aboutLink } = data;

  return (
    <section className="mb-5 lg:mb-10">
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {/*  Sloupec: Bezkonkurenční servis */}
        <div
          className={classNames(
            'relative overflow-hidden lg:col-span-2 p-2 lg:px-4 lg:py-3 lg:flex lg:flex-col lg:justify-end sm:rounded-sm lg:rounded-l-sm lg:text-white sm:bg-primary-yellow',
            !image && 'bg-primary-yellow',
          )}
        >
          <h2 className="h2 mb-0.5">
            <Trans i18nKey="hpservices.header" />
          </h2>
          <p className="lg:w-7/12 mb-2 sm:text-14">
            <Trans i18nKey="hpservices.paragraph" />
          </p>

          <ButtonLink
            href={aboutLink || URLS.HOMEPAGE}
            variant="secondary"
            size="big"
            className="lg:hidden"
          >
            <Trans i18nKey="hpservices.button" />
          </ButtonLink>

          <div className="sm:relative sm:overflow-hidden sm:h-48 sm:mt-3 sm:-mx-2 sm:-mb-2">
            {image && (
              <div className="absolute inset-0 sm:z-0 w-full h-full lg:z-background">
                {/* Překryvná vrstva přes obrázek */}
                <div className="absolute inset-0 sm:z-0 w-full h-full lg:z-0 lg:bg-gradient-to-t from-black-50" />
                <StrapiImage image={image} alt={t('hpservices.header')} />
              </div>
            )}
          </div>
        </div>
        {/* Sloupec: Služby přehledně */}
        <div className="sm:hidden p-4 rounded-r-sm bg-primary-yellow">
          <h3 className="h3 mb-1">
            <Trans i18nKey="hpservices.links.header" />
          </h3>
          <ul>
            {links?.map(({ id, url, title, page, page_template }) => (
              <ServicesLink
                key={id}
                text={title}
                url={page?.generatedUrl || page_template?.generatedUrl || url}
              />
            ))}
          </ul>

          <ButtonLink
            href={aboutLink || URLS.HOMEPAGE}
            variant="secondary"
            size="big"
            className="mt-4"
          >
            <Trans i18nKey="hpservices.button" />
          </ButtonLink>
        </div>
      </div>
    </section>
  );
};

export default Services;
