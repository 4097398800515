import { Trans } from 'next-i18next';
import React from 'react';
import { Button } from '@/components/atoms/Button';

interface MoreConnectionsButtonsProps {
  isToday: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

const MoreConnectionsButtons: React.FC<MoreConnectionsButtonsProps> = ({
  isToday,
  onNextClick,
  onPreviousClick,
}) => (
  <div className="flex justify-between">
    {isToday ? (
      <div />
    ) : (
      <Button className="whitespace-nowrap" variant="secondary" onClick={onPreviousClick}>
        <Trans i18nKey="button.searchPrevious" />
      </Button>
    )}
    <Button className="whitespace-nowrap" variant="secondary" onClick={onNextClick}>
      <Trans i18nKey="button.searchNext" />
    </Button>
  </div>
);

export default MoreConnectionsButtons;
