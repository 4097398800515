import React from 'react';
import VikendoWrapper from '@/components/content/vikendo/VikendoWrapper';
import { ShopHpVikendoEntity } from '@/models/types';

interface VikendoIframeProps {
  data: ShopHpVikendoEntity;
}

/**
 * Víkendo component
 * @var show Zobrazovat / skrýt obsah
 */
const Vikendo: React.FC<VikendoIframeProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { show } = data;

  return show ? (
    <section className="mb-5 lg:mb-10">
      <VikendoWrapper />
    </section>
  ) : null;
};

export default Vikendo;
