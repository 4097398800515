import DynamicLink from '../atoms/DynamicLink';
import PageHeroBanner from './PageHeroBanner';
import React, { FC } from 'react';
import { ShopHpSecondaryBannerEntity } from '@/models/types';

interface SecondaryBannerProps {
  data: ShopHpSecondaryBannerEntity;
}

const SecondaryBanner: FC<SecondaryBannerProps> = ({ data }) => {
  if (!data || !data?.heroBanner) return null;

  const { title, heroBanner, url } = data;

  const Component = url
    ? () => (
        <DynamicLink href={url}>
          <PageHeroBanner component={heroBanner} />
        </DynamicLink>
      )
    : () => <PageHeroBanner component={heroBanner} />;

  return (
    <section className="mb-5 lg:mb-10">
      {!!title && <h1 className="h1 mb-2">{title}</h1>}
      <Component />
    </section>
  );
};

export default SecondaryBanner;
