import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import Banner from '@/components/atoms/Banner';
import { Button } from '@/components/atoms/Button';
import BenefitList from '@/components/benefitBanner/BenefitList';
import useModal from '@/components/modal/useModal';
import RegistrationBox from '@/components/registration/RegistrationBox';
import { RocketLg } from '@/icons';

interface BenefitBannerProps {
  className?: string;
  fullWidth?: boolean;
}

const BenefitBanner: React.FC<BenefitBannerProps> = ({ className, fullWidth }) => {
  const { showModal } = useModal();

  return (
    <Banner
      className={classNames(
        className,
        'flex flex-col items-start p-1 lg:p-3 bg-primary-yellowlight3',
      )}
      icon={<RocketLg className="opacity-100 w-1/2" />}
    >
      <h2 className="h2 mb-2">
        <Trans i18nKey="loginBox.registerColumn.paragraph" />
      </h2>
      <BenefitList fullWidth={fullWidth} />
      <Button
        className="mt-2"
        size="big"
        onClick={() => showModal(<RegistrationBox />, { removePadding: true })}
      >
        <Trans i18nKey="loginBox.registerColumn.button" />
      </Button>
    </Banner>
  );
};
export default BenefitBanner;
