import React from 'react';
import { parse } from 'qs';
import DynamicLink from '@/components/atoms/DynamicLink';
import StrapiImage from '@/components/content/StrapiImage';
import { ShopHpMainBannerEntity } from '@/models/types';
import { isVisible } from '@/utils/dateTimeUtils';
import { isQueryValid } from '@/utils/routerUtils';

interface MainBannerProps {
  data: ShopHpMainBannerEntity;
}

/**
 * Hero banner
 * @var imageDesktop Obrázek přes celou šířku (desktop)
 * @var imageMobile Obrázek přes celou šířku (mobile)
 * @var header Nadpis
 * @var subheader Malý text (odkaz)
 */

const MainBanner: React.FC<MainBannerProps> = ({ data }) => {
  if (!data?.banner?.length) return null;

  const visibleBanner = (data?.banner || []).find(({ visibility }) =>
    isVisible(visibility?.visibleFrom, visibility?.visibleTo),
  );

  const { header, subheader, imageMobile, imageDesktop, BgHexColor } = visibleBanner;
  return header &&
    !isQueryValid(parse(global.location?.search, { ignoreQueryPrefix: true }), 'search') ? (
    <DynamicLink id="main-banner-link" href={subheader?.url || ''}>
      <div className="relative z-0 overflow-hidden bg-primary-bluelight2">
        {/* Mobilní a desktopový obrázek jsou zvlášť */}
        <StrapiImage
          id="gtm-main-banner-mobile"
          alt={header}
          image={imageMobile}
          className="lg:hidden"
          aria-hidden
        />
        <StrapiImage
          id="gtm-main-banner-desktop"
          alt={header}
          image={imageDesktop}
          className='sm:hidden w-full h-full object-contain'
          fitCover={false}
          aria-hidden
          style={{
            backgroundColor: BgHexColor,
          }}
        />
        <div className="absolute top-0 left-0 z-10 w-full h-full" />
        <div className="page-layout absolute top-1/2 left-0 right-0 z-20 transform -translate-y-1/2">
          {/**
           * Margin-bottom kompenzuje překryv <SearchBox> zespoda do obrázku
           * Cíl: text je vizuálně vertikálně vycentrován na střed
           */}
          <div className="lg:mb-14 text-neutral-white text-shadow-heroBanner">
            {/* Nadpis */}
            <h1 className="h1 sm:text-h2 text-headline">{header}</h1>
            {subheader && (
              /* Podnadpis */
              <div className="font-bold">{subheader?.text}</div>
            )}
          </div>
        </div>
      </div>
    </DynamicLink>
  ) : null;
};

export default MainBanner;
